.link {
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 12px;
  padding-block: 4px;
  margin-block: 2px;
  color: #000;
  width: 100%;
  /* height: 2rem; */
  width: 13rem;
  font-size: 18px;
  font-weight: 400;
}
.link:hover {
  color: #fff;
  /* color: #ffa500; */
  background-color: #adadad;
}
.activelink {
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 12px;
  padding-block: 4px;
  margin-block: 2px;
  color: #000;
  background-color: #adadad;
  width: 100%;
  color: #fff;
  /* height: 2rem; */
  width: 13rem;
  font-size: 18px;
  font-weight: 400;
}
.select {
  flex: 1;
  display: flex;
  background-color: #000;
  width: 100%;
}
